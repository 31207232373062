module.exports = {
  defaultTitle: 'Tinsley Lau',
  logo: 'https://www.tinsleylau.com/favicon/favicon-512.png',
  author: 'Tinsley Lau',
  url: 'https://www.tinsleylau.com',
  legalName: 'Tinsley Lau',
  defaultDescription: 'I’m Tinsley and I’m a software engineer!',
  socialLinks: {
    github: 'https://github.com/lautin0',
    linkedin: 'https://www.linkedin.com/in/tinsley-lau-655448134',
    instagram: 'https://www.instagram.com/tinyulau'
  },
  googleAnalyticsID: 'UA-134252290-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2021',
};
