import styled from "styled-components";

export const BadgeCard = styled.div`      
   display: flex;
   align-items: center;   

   padding-left: 1rem;
   padding-right: 1rem;
   
   color: gray;

   border-radius: .5rem;
   background-color: white;
   box-shadow: 0px 1px 6px 0px lightgray;

   min-height: 3rem;
   width: 24rem;

   @media (max-width: 960px) {
    width: 18rem;
  }
`