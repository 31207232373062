import styled from "styled-components";

export const GreenLightDot = styled.div`
   width: 1rem;
   height: 1rem;
   border-radius: 9999px;
   background: rgb(26,199,43);
   background: radial-gradient(circle, rgba(26,199,43,1) 5%, rgba(166,255,184,1) 60%, rgba(255,255,255,1) 90%);
`
export const RedLightDot = styled.div`
   width: 1rem;
   height: 1rem;
   border-radius: 9999px;
   background: rgb(199,26,26);
   background: radial-gradient(circle, rgba(199,26,26,1) 5%, rgba(255,166,166,1) 60%, rgba(255,255,255,1) 90%);
`
export const YellowLightDot = styled.div`
   width: 1rem;
   height: 1rem;
   border-radius: 9999px;
   background: rgb(255,194,25);
   background: radial-gradient(circle, rgba(255,194,25,1) 5%, rgba(253,255,166,1) 60%, rgba(255,255,255,1) 90%);
`